
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { IReceivedOrder } from "@/types/received_order";
import { ICustomer } from "@/types/customer";
import { IBranch } from "@/types/branch";
import { ICustomerStaff } from "@/types/customer_staff";
import { Shipment } from "@/models/shipment";
import { IDuplicateProduct, IDuplicateProductRequest } from "@/types/ship_slip";
import { IProductReceivedOrder } from "@/types/product_received_order";
import { ProductReceivedOrder } from "@/models/product_received_order";
import { IUserListRequest } from "@/types/user";
import { ISelectItem } from "@/types";

@Component({
  components: {}
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // received_order
  @Action("received_order/adminGet")
  public getReceivedOrder!: (id: number) => Promise<boolean>;

  @Action("received_order/adminUpdateDeliveryDate")
  public updateDeliveryDate!: (data: {
    received_order_id: number;
    params: {
      is_asap: number;
      desired_delivery_date: string | null;
      fixed_delivery_date: string | null;
    };
  }) => Promise<boolean>;

  @Getter("received_order/single")
  public received_order!: IReceivedOrder;

  @Mutation("received_order/clear")
  public clearReceivedOrder!: () => void;

  //customer
  @Action("customer/adminGet")
  public getCustomer!: (customer_id: number) => Promise<boolean>;

  @Getter("customer/single")
  public customer!: ICustomer;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  //branch
  @Action("branch/adminGet")
  public getBranch!: (branch_id: number) => Promise<boolean>;

  @Getter("branch/single")
  public branch!: IBranch;

  @Mutation("branch/clear")
  public clearBranch!: () => void;

  //customer_staff
  @Action("customer_staff/adminGet")
  public getCustomerStaff!: (customer_staff_id: number) => Promise<boolean>;

  @Getter("customer_staff/single")
  public customer_staff!: ICustomerStaff;

  @Mutation("customer_staff/clear")
  public clearCustomerStaff!: () => void;

  //user
  @Action("user/adminGetList")
  public getUsers!: (params: IUserListRequest) => Promise<boolean>;

  @Getter("user/selectItem")
  public userList!: ISelectItem[];

  // pdf
  @Action("pdf/adminPDF")
  public getPDF!: (id: number) => Promise<boolean>;

  @Getter("pdf/size")
  public size!: "";

  // ship_slip
  @Action("ship_slip/adminDuplicateCheck")
  public duplicateCheck!: (
    duplicate_product_params: IDuplicateProductRequest
  ) => Promise<boolean>;

  @Getter("ship_slip/duplicateProduct")
  public duplicate_product!: IDuplicateProduct[];

  // fields
  public received_order_id = 0;
  public customer_id = 0;
  public branch_id = 0;
  public customer_staff_id = 0;
  public update_is_asap = 0;
  public update_desired_delivery_date: string | null = "";
  public update_fixed_delivery_date: string | null = "";
  public product_received_order: IProductReceivedOrder[] = [];
  public remaining_shipments: number[] = [];
  public duplicate_product_params: IDuplicateProductRequest = {
    shipment_id: 0
  };
  public disp = false;
  public delivery_date_dialog = false;
  public is_update_fixed_delivery_date = false;
  public menu = {
    update_desired_delivery_date: false,
    update_fixed_delivery_date: false
  };

  public async created() {
    this.received_order_id = Number(this.$route.params.received_order_id);
    this.clearReceivedOrder();
    this.clearCustomer();
    this.clearBranch();
    this.clearCustomerStaff();

    await Promise.all([
      this.getReceivedOrder(this.received_order_id),
      this.getUsers({ per_page: 0 })
    ]);

    // 修正用希望納品日、回答納品日セット
    this.update_is_asap = this.received_order.is_asap;
    this.update_desired_delivery_date =
      this.received_order.desired_delivery_date;
    this.update_fixed_delivery_date = this.received_order.fixed_delivery_date;

    //product_received_order(受注数量とproduct_id)セット
    for (let [index, product] of this.received_order.products.entries()) {
      this.product_received_order.push(new ProductReceivedOrder());
      this.product_received_order[index].quantity = product.quantity;
      this.product_received_order[index].product_id = product.product_id;
      //残出荷数量計算
      await this.remainingShipments(this.product_received_order[index], index);
    }

    // 受注者(顧客)情報をもってくる
    await this.getCustomerInfomation();
    this.disp = true;
  }

  public getCustomerInfomation() {
    this.customer_id = this.received_order.customer_id;
    this.getCustomer(this.customer_id);
    if (this.received_order.branch_id) {
      this.getBranch(this.received_order.branch_id);
    }
    if (this.received_order.customer_staff_id) {
      this.getCustomerStaff(this.received_order.customer_staff_id);
    }
  }

  public postalcode_insert(val: string) {
    return val ? "〒" + val.slice(0, 3) + "-" + val.slice(3, val.length) : "";
  }

  public shipment_detail(shipment_id: Shipment) {
    this.$router.push(`/admin/shipment/${shipment_id}`);
  }

  //product_received_order(受注数量とproduct_id)セット
  public productReceivedOrderSet() {
    for (let [
      index,
      product_received_order
    ] of this.received_order.products.entries()) {
      this.product_received_order.push(new ProductReceivedOrder());
      this.product_received_order[index].quantity =
        product_received_order.quantity;
      this.product_received_order[index].product_id =
        product_received_order.product_id;
    }
  }

  //残出荷数量計算
  public async remainingShipments(
    product_received_order: IProductReceivedOrder,
    index: number
  ) {
    this.remaining_shipments[index] = product_received_order.quantity || 0;
    for (let [, shipment] of this.received_order.shipment.entries()) {
      for (let [, product] of shipment.product_shipment.entries()) {
        if (product_received_order.product_id == product.product_id) {
          this.remaining_shipments[index] -= product.quantity;
        }
      }
    }
  }

  // 希望納品日、入荷予定日更新
  public async desiredDeliveryDateSubmit(
    is_update_fixed_delivery_date: boolean
  ) {
    this.delivery_date_dialog = false;

    await this.updateDeliveryDate({
      received_order_id: this.received_order_id,
      params: {
        is_asap: is_update_fixed_delivery_date
          ? this.received_order.is_asap
          : this.update_is_asap,
        desired_delivery_date: is_update_fixed_delivery_date
          ? this.received_order.desired_delivery_date
          : this.update_desired_delivery_date,
        fixed_delivery_date: is_update_fixed_delivery_date
          ? this.update_fixed_delivery_date
          : this.received_order.fixed_delivery_date
      }
    });

    this.$router.go(0);
  }

  public closeDesiredDeliveryDateDialog() {
    this.delivery_date_dialog = false;
    this.update_is_asap = this.received_order.is_asap;
    this.update_desired_delivery_date =
      this.received_order.desired_delivery_date;
    this.update_fixed_delivery_date = this.received_order.fixed_delivery_date;
  }

  // 受注商品
  public product_headers = [
    {
      text: "",
      value: "division_storing",
      class: "px-0",
      cellClass: "px-0",
      sortable: false
    },
    {
      text: "商品名",
      value: "product_name",
      sortable: false
    },
    {
      text: "品番",
      value: "product_code",
      sortable: false
    },
    {
      text: "顧客品番",
      value: "customer_pn",
      sortable: false
    },
    {
      text: "発注番号メモ",
      value: "orders_memo",
      sortable: false
    },
    {
      text: "受注商品メモ",
      value: "memo",
      sortable: false
    },
    {
      text: "受注数量",
      value: "quantity",
      sortable: false,
      align: "end"
    },
    {
      text: "残出荷数量",
      value: "remaining_shipment",
      sortable: false,
      align: "end"
    },
    {
      text: "売単価",
      value: "selling_unit_price",
      sortable: false,
      align: "end"
    },
    {
      text: "売単価合計",
      value: "amount",
      sortable: false,
      align: "end"
    }
  ];

  // 諸経費ヘッダー
  public price_tag_headers = [
    {
      text: "",
      value: "",
      sortable: false,
      width: "55%"
    },
    {
      text: "諸経費名",
      value: "price_tag_name",
      sortable: false
    },
    {
      text: "顧客品番",
      value: "customer_pn",
      sortable: false
    },
    {
      text: "注文番号",
      value: "price_tag_no",
      sortable: false
    },
    {
      text: "記載金額",
      value: "price",
      sortable: false,
      align: "end"
    }
  ];

  // 受注商品の配列を整える
  get setValue() {
    return this.received_order.product_received_order.map((el, index) => ({
      division_storing:
        this.remaining_shipments[index] != 0 &&
        this.remaining_shipments[index] != el.quantity,
      product_name: el.product_name,
      product_code: el.product_code,
      customer_pn: el.customer_pn,
      orders_memo: el.orders_memo,
      memo: el.content,
      selling_unit_price: Number(el.selling_unit_price),
      amount: Number(el.amount),
      quantity: Number(el.quantity),
      remaining_shipment: this.remaining_shipments[index]
    }));
  }

  // 出荷記録
  public shipment_headers = [
    { text: "商品名", value: "product_name", sortable: false },
    { text: "ロットNo.", value: "lot_no", sortable: false },
    {
      text: "出荷数量",
      value: "shipped_quantity",
      sortable: false,
      align: "end"
    },
    {
      text: "出荷日",
      value: "shipment_date",
      sortable: false
    },
    {
      text: "出荷状況",
      value: "shipment_status",
      sortable: false
    },
    { text: "伝票番号", value: "id", sortable: false },
    { text: "登録者", value: "user_name", sortable: false },
    {
      text: "承認者",
      value: "authorizer_name",
      sortable: false
    },
    {
      text: "詳細",
      value: "shipment",
      sortable: false
    }
  ];
}
